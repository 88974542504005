<template>
  <div class="schedule">
    <v-row>
      <v-col cols="12" md="6">
        <div class="schedule-card">
          <div class="schedule-card__title">Set up automatic synchronization</div>

          <v-form ref="personioScheduleForm" class="personioScheduleForm" @submit.prevent>
            <v-select
              v-model="repeat"
              :items="repeatOptions"
              label="Repetition"
              placeholder="Repetition"
              outlined
              hide-details
              dense
              class="mb-5 mt-2"
              @change="(saveChangesAllowed = true), setRepeatOptions()"
            ></v-select>
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              class="datepicker-month"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  outlined
                  dense
                  label="Time of sync
                  "
                  readonly
                  v-bind="attrs"
                  hide-details
                  class="mb-5"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="time"
                format="24hr"
                color="primary"
                full-width
                hide-details
                class="mb-0"
                :min="minAllowedTime"
                @click:minute="$refs.menu.save(time)"
                @change="saveChangesAllowed = true"
              ></v-time-picker>
            </v-menu>
            <v-text-field
              v-if="repeat === null"
              label="N/A"
              readonly
              outlined
              dense
              hide-details
              disabled
              value="Please choose a repetition"
              filled
              class="mb-5"
            ></v-text-field>
            <v-menu
              v-if="repeat === 'ONCE' || repeat === 'ONCE'"
              ref="menurefdate"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Date of sync
                  "
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                full-width
                :min="minAllowedDate"
                :max="maxAllowedDate"
                color="primary"
                :picker-date.sync="date"
                @click:date="$refs.menurefdate.save(date)"
                @change="(saveChangesAllowed = true), setRepeatOptions()"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-if="repeat === 'MONTHLY'"
              ref="menurefdatemonth"
              v-model="menu4"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="
                  Date of sync
                  "
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  hide-details
                  :value="'on the ' + moment(dateMonth).format('Do')"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateMonth"
                full-width
                :min="minAllowedDate"
                :max="maxAllowedDate"
                color="primary"
                :class="repeat === 'MONTHLY' ? 'datepicker-month' : ''"
                :no-title="repeat === 'MONTHLY'"
                :show-current="false"
                @click:date="$refs.menurefdatemonth.save(date)"
                @change="(saveChangesAllowed = true), setRepeatOptions()"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-if="repeat === 'TWICEMONTHLY'"
              ref="menurefdatemonthbi"
              v-model="menu4"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="
                    'Dates of sync'
                  "
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  hide-details
                  :value="
                    'on the ' + moment(dateTwiceMonth[0]).format('Do') + '/' + moment(dateTwiceMonth[1]).format('Do')
                  "
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="dateTwiceMonth"
                full-width
                :min="minAllowedDate"
                :max="maxAllowedDate"
                color="primary"
                :class="repeat === 'TWICEMONTHLY' ? 'datepicker-month datepicker-range' : ''"
                :no-title="repeat === 'TWICEMONTHLY'"
                :show-current="false"
                range
                @click:date="dateTwiceMonth.length === 2 ? $refs.menurefdatemonthbi.save(date) : ''"
                @change="(saveChangesAllowed = true), setRepeatOptions()"
              >
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-if="repeat === 'DAILY'"
              label="N/A"
              readonly
              outlined
              dense
              hide-details
              :disabled="repeat === 'DAILY'"
              value="Day or date does not apply"
              filled
              class="mb-5"
            ></v-text-field>
            <v-select
              v-if="repeat === 'WEEKLY'"
              v-model="menu3"
              :items="dayOptions"
              label="Day of sync"
              placeholder="Please choose a day"
              outlined
              hide-details
              dense
              class="mb-5 mt-2"
              @change="(saveChangesAllowed = true), setRepeatOptions()"
            ></v-select>
            <!-- <v-select
              :items="dayOptions"
              v-model="menu5"
              label="Day of sync"
              placeholder="Please choose a day"
              outlined
              hide-details
              @change="(saveChangesAllowed = true), setRepeatOptions()"
              dense
              class="mb-5 mt-2"
              v-if="repeat === 'BIWEEKLY'"
            ></v-select> -->
            <v-btn
              color="primary"
              :disabled="!time || !date || !repeat || isSyncRunning || !saveChangesAllowed"
              class="schedule-card__btn"
              :class="{
                'schedule-card__btn--disabled': !time || !date || !repeat,
              }"
              type="submit"
              @click="saveSchedule"
            >
              Save changes
            </v-btn>
          </v-form>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="schedule-card d-flex flex-column justify-space-between">
          <div class="schedule-card__title mb-0">Start manual instant synchronization</div>
          <div class="schedule-card__icons justify-center">
            <v-icon size="120" color="#898989" class="schedule-card__db">
              {{ icons.mdiDatabase }}
            </v-icon>
            <v-icon
              size="49"
              color="#898989"
              class="schedule-card__sync animation-spin-back"
              :class="{ 'animation-spin-back': isSyncRunning }"
            >
              {{ icons.mdiSync }}
            </v-icon>
            <v-icon size="120" color="#898989" class="schedule-card__db">
              {{ icons.mdiDatabase }}
            </v-icon>
          </div>
          <v-btn
            color="primary"
            class="schedule-card__btn mt-0"
            :disabled="isSyncRunning"
            type="submit"
            @click="syncNow"
          >
            Sync now
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div class="d-flex mt-10">
      <v-btn outlined depressed color="primary" class="flex-button personio-step__auth-btn" @click="$emit('stepBack')">
        Back to filter selection
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="flex-button personio-step__auth-btn accent--text" @click="finishSetup">
        Finish personio connector
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref, watch, toRefs, onMounted, getCurrentInstance } from '@vue/composition-api';
import { mdiClockTimeFourOutline, mdiDatabase, mdiSync } from '@mdi/js';
import moment from 'moment';
import { postPersonioSync, putUpdatePersonio, setAutosyncStatus, signPersonioActivity } from '@/api/personio-connector';
import { ADMIN_ACTIVITY_TYPE } from '@/constants';

const DAILY = 'DAILY';
const ONCE = 'ONCE';
const WEEKLY = 'WEEKLY';
const BIWEEKLY = 'BIWEEKLY';
const MONTHLY = 'MONTHLY';
const TWICEMONTHLY = 'TWICEMONTHLY';

export default {
  name: 'PersonioConnnectStepSchedule',
  props: {
    companySelected: {
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const time = ref('00:00');
    const date = ref(null);
    const dateMonth = ref(null);
    const dateTwiceMonth = ref(null);
    const dateFormatted = ref(null);
    const dateFormatted2 = ref('2022-05-01');
    const repeat = ref(null);
    const saveChangesAllowed = ref(false);
    const menu1 = ref(false);
    const menu2 = ref(false);
    const menu3 = ref(false);
    const menu4 = ref(false);
    const menu5 = ref(false);
    const repeatOptions = ref([
      {
        text: `One time`,
        value: ONCE,
      },
      {
        text: 'Every day',
        value: DAILY,
      },
      {
        text: `Every week`,
        value: WEEKLY,
      },
      // {
      //   text: `Every two weeks`,
      //   value: BIWEEKLY,
      // },
      {
        text: `Every month`,
        value: MONTHLY,
      },
      {
        text: `Twice monthly`,
        value: TWICEMONTHLY,
      },
    ]);
    const minAllowedDate = ref(null);
    const maxAllowedDate = ref(null);
    const minAllowedTime = ref(null);
    const dayOptions = ref([
      { text: 'Monday', value: 'Mon' },
      { text: 'Tuesday', value: 'Tue' },
      { text: 'Wednesday', value: 'Wed' },
      { text: 'Thursday', value: 'Thu' },
      { text: 'Friday', value: 'Fri' },
      { text: 'Saturday', value: 'Sat' },
      { text: 'Sunday', value: 'Sun' },
    ]);
    const isSyncRunning = ref(false);
    const isEditingSetup = ref(false);
    const { companySelected } = toRefs(props);

    onMounted(() => {
      date.value = new Date().toISOString().substr(0, 10);
    });

    const syncNow = () => {
      isSyncRunning.value = true;
      postPersonioSync(companySelected.value.id)
        .then((res) => {
          signManualSync();
          isSyncRunning.value = false;
          vm.$store.commit('showMessage', {
            text: `Your manual sync was successful!`,
            color: 'teal',
            timeout: '5000',
          });
        })
        .catch(() => {
          vm.$store.commit('showMessage', {
            text: `Your manual sync failed!`,
            color: 'error',
            timeout: '5000',
          });
          signManualSync(false);
          isSyncRunning.value = false;
        });
    };

    const changeAutoSyncStatus = (companyId, status) => {
      setAutosyncStatus(companyId, status);
    };

    const signActivity = () => {
      const userName =
        userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;
      const msg = `${userName} setup automatic sync`;
      signPersonioActivity(companySelected.value.id, userData.id, { msg }, ADMIN_ACTIVITY_TYPE.INFO);
    };

    const signManualSync = (success = true, errorLog = []) => {
      const userName =
        userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;
      const msg = `Manual sync by ${userName} ${success ? 'was successful' : 'failed'}`;
      const status = success ? ADMIN_ACTIVITY_TYPE.INFO : ADMIN_ACTIVITY_TYPE.ERROR;
      signPersonioActivity(companySelected.value.id, userData.id, { msg, errorLog }, status);
    };

    const saveSchedule = () => {
      const cronExpression = dateToCron();
      if (!cronExpression) return;

      isSyncRunning.value = true;
      isEditingSetup.value = false;
      changeAutoSyncStatus(companySelected.value.id, false);

      putUpdatePersonio(companySelected.value.id, {
        portalCompanyId: companySelected.value.id,
        schedule: cronExpression,
      })
        .then(() => {
          isSyncRunning.value = false;
          saveChangesAllowed.value = false;
          changeAutoSyncStatus(companySelected.value.id, true);
          signActivity();
          isEditingSetup.value = false;
          vm.$store.commit('showMessage', {
            text: `You have successfully set up automatic regular synchronization!`,
            color: 'teal',
            timeout: '5000',
          });
        })
        .catch(() => {
          isEditingSetup.value = true;
          isSyncRunning.value = false;
          saveChangesAllowed.value = true;
        });
    };

    const dateToCron = () => {
      let cron;
      const onTime = time.value.split(':');
      const onDay = moment(date.value).format('ddd');
      const onDate = moment(date.value).format('D');
      const onDateMonth = moment(dateMonth.value).format('D');
      const onDateTwiceMonth = `${moment(dateTwiceMonth.value[0]).format('D')},${moment(dateTwiceMonth.value[1]).format(
        'D',
      )}`;
      const onMonth = moment(date.value).format('MMM');
      const onYear = moment(date.value).year();
      if (repeat.value === DAILY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ? * * *`;
      } else if (repeat.value === WEEKLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ? * ${menu3.value} *`;
      } else if (repeat.value === BIWEEKLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} * * ${menu5.value} ?`;
      } else if (repeat.value === MONTHLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ${onDateMonth} * ? *`;
      } else if (repeat.value === TWICEMONTHLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ${onDateTwiceMonth} * ? *`;
      } else if (repeat.value === ONCE) {
        cron = `0 ${onTime[1]} ${onTime[0]} ${onDate} ${onMonth} ? ${onYear}`;
      } else {
        return false;
      }

      return cron;
    };

    const finishSetup = () => {
      putUpdatePersonio(companySelected.value.id, {
        portalCompanyId: companySelected.value.id,
        step: 4,
        draft: false,
      }).then(() => {
        isEditingSetup.value = false;
        vm.$store.commit('showMessage', {
          text: `The connection to Personio from ${companySelected.value.companyName} was successful!`,
          color: 'teal',
          timeout: '5000',
        });

        vm.$router.push(`/personio-companies/${companySelected.value.id}`);
      });
    };

    const disabledDates = () => {
      return {
        to: new Date(Date.now()),
      };
    };

    const setAllowedDates = () => {
      if (repeat.value === ONCE) {
        minAllowedDate.value = moment().format('YYYY-MM-DD');
        maxAllowedDate.value = null;
      }
      if (repeat.value === MONTHLY) {
        minAllowedDate.value = moment('2022-05-01').format('YYYY-MM-DD');
        maxAllowedDate.value = moment('2022-05-31').format('YYYY-MM-DD');
      }
    };

    const setMinAllowedTime = () => {
      if (repeat.value === ONCE) {
        if (
          moment([date.value, time.value], 'YYYY-MM-DD HH:mm').isSameOrBefore(moment(), time) ||
          moment([date.value], 'YYYY-MM-DD').isSameOrBefore(moment(), date)
        ) {
          time.value = moment().add(5, 'minutes').format('HH:mm');
          minAllowedTime.value = moment().add(5, 'minutes').format('HH:mm');
        } else {
          minAllowedTime.value = null;
        }
      } else {
        minAllowedTime.value = null;
      }
    };

    const setRepeatOptions = () => {
      setAllowedDates();
      setMinAllowedTime();
    };

    const setDefaultOptions = () => {
      repeat.value = null;
      date.value = new Date().toISOString().substr(0, 10);
      menu3.value = moment().format('ddd');
      menu5.value = moment().format('ddd');
      time.value = moment().add(5, 'minutes').format('HH:mm');
      dateMonth.value = `2022-05-${moment().format('DD')}`;
      dateTwiceMonth.value = ['2022-05-05', '2022-05-25'];
    };

    const reset = () => {
      isEditingSetup.value = false;
      // Should reset fields that exist in this step
      return true;
    };
    onMounted(() => {
      setDefaultOptions();
      setRepeatOptions();
    });

    watch(date, (val) => {
      dateFormatted.value = moment.utc(val).local().format('DD.MM.YYYY');
    });

    return {
      isSyncRunning,
      time,
      date,
      dateMonth,
      dateTwiceMonth,
      menu2,
      menu1,
      moment,
      dateFormatted,
      dateFormatted2,
      repeat,
      repeatOptions,
      saveSchedule,
      syncNow,
      finishSetup,
      disabledDates,
      isEditingSetup,
      reset,
      setAllowedDates,
      setMinAllowedTime,
      setRepeatOptions,
      setDefaultOptions,
      dayOptions,
      menu3,
      menu4,
      menu5,
      minAllowedTime,
      minAllowedDate,
      maxAllowedDate,
      saveChangesAllowed,
      icons: {
        mdiClockTimeFourOutline,
        mdiDatabase,
        mdiSync,
      },
    };
  },
};
</script>

<style lang="scss">
.schedule {
  padding: 0 18px;
  &-card {
    background: #ffffff;
    border: 1px solid rgba(137, 137, 137, 0.5);
    border-radius: 3px;
    padding: 16px 80px;
    height: auto;
    min-height: 342px;
    text-align: center;
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #2830ce;
      margin-bottom: 40px;
    }
    &__btn {
      border-radius: 3px;
      width: 100% !important;
      margin-top: 40px;
      &--disabled {
        background-color: #898989 !important;
        border-radius: 3px;
        color: white !important;
      }
    }
    &__icons {
      width: 100%;
      align-items: center;
      display: flex;
      min-height: 172px;
    }
    &__sync {
      margin: 0 50px;
    }
  }
}
.datepicker-month {
  padding-top: 8px;
  padding-bottom: 8px;
  &.datepicker-range {
    .v-btn:not(.v-date-picker--first-in-range, .v-date-picker--last-in-range) {
      border-color: white;
      &:before {
        background: white;
        opacity: 1;
        border-color: white !important;
      }
      .v-btn__content {
        color: black;
      }
    }
  }

  .v-date-picker-table {
    display: flex;
    thead tr th {
      display: none;
    }
  }
  .v-date-picker-header {
    display: none;
  }
}
</style>
