<template>
  <div>
    <v-row class="mb-6">
      <v-col cols="12" md="10" class="mx-auto">
        <a href="#" class="personio-connect__back" @click.prevent="$router.push('/personio-companies')">
          <v-icon color="primary">{{ icons.mdiChevronDoubleLeft }}</v-icon>
          <span class="ml-2">Back to overview of personio companies</span>
        </a>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <v-card class="v-card--no-border v-card--no-bg d-flex justify-center">
          <div class="personio-connect__search">
            <h3 class="font-weight-bold personio-connect__title">
              For which company you want to set up the Personio Connector?
            </h3>
            <v-autocomplete
              v-model="selectedCompany"
              class="personio-connect__input"
              :search-input.sync="search"
              :items="availableCompanies"
              :loading="loadingCompanies"
              :placeholder="$t('Search company')"
              return-object
              :no-filter="true"
              dense
              clearable
              outlined
              :hide-no-data="loadingCompanies"
              hide-details
              :append-icon="icons.mdiMagnify"
              @click:clear="() => $router.push('')"
            >
              <template slot="item" slot-scope="{ item }">
                <span>{{ item.companyName }}</span>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <span>{{ item.companyName }}</span>
              </template>
              <template slot="no-data">
                <span class="px-2">{{ $t('No results') }}</span>
              </template>
            </v-autocomplete>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div class="personio-connect__overlay" :class="{ disabled: !selectedCompany }">
      <v-row class="justify-center">
        <v-col cols="12" md="10" class="mx-auto">
          <PersonioConnectStepper ref="stepper" :companySelected="selectedCompany" />
        </v-col>
      </v-row>
    </div>

    <ModalWindow :is-open="leaveConfirmationModal" :max-width="616" warning>
      <template #content>
        <p class="text-base confirm-modal__text mb-0">
          {{ routeLeaveMessage }}
        </p>
      </template>
      <template #actions>
        <div class="confirm-modal__btns mt-2">
          <v-btn outlined class="confirm-modal__btn" color="primary" @click="leaveConfirmationModal = false">{{
            $t('No, continue')
          }}</v-btn>
          <v-btn color="primary" class="confirm-modal__btn" @click="confirmNavigationWithoutSaving">{{
            $t('Yes, cancel')
          }}</v-btn>
        </div>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { mdiMagnify, mdiChevronDoubleLeft } from '@mdi/js';
import { ref, watch, getCurrentInstance } from '@vue/composition-api';
import ModalWindow from '@/components/modal/ModalWindow';
import PersonioConnectStepper from './personio-connect-tabs/PersonioConnectStepper';
import { getCompaniesList, getCompanyById } from '../../api/companies';

export default {
  name: 'GlobalAdminPersonioConnect',
  components: { PersonioConnectStepper, ModalWindow },
  beforeRouteLeave(to, from, next) {
    const { stepper } = this.$refs;
    const { activeStep } = stepper;
    const { stepAuth } = stepper.$refs;
    const { stepFields } = stepper.$refs;
    const { stepValues } = stepper.$refs;
    const { stepFilters } = stepper.$refs;
    const { stepSchedule } = stepper.$refs;

    switch (activeStep) {
      case 1:
        if (stepAuth.personioId && stepAuth.personioSecret) {
          this.routeLeaveMessage =
            'Are you sure you want to cancel the Personio setup? The credentials will not be saved!';
          this.showConfirmationModal(to.path, stepAuth);
        } else {
          next();
        }
        break;
      case 2:
        if (stepFields.isEditingSetup) {
          this.routeLeaveMessage =
            'Are you sure you want to cancel the personio setup now?\n You have not yet saved the field mapping by clicking the continue button, which would prevent this data from being transferred!';
          this.showConfirmationModal(to.path, stepFields);
        } else {
          next();
        }
        break;
      case 3:
        if (stepValues.isEditingSetup) {
          this.routeLeaveMessage =
            'Are you sure you want to cancel the personio setup now?\n You have not yet saved the value mapping by clicking the continue button, which would prevent this data from being transferred!';
          this.showConfirmationModal(to.path, stepValues);
        } else {
          next();
        }

        break;
      case 4:
        if (stepFilters.isEditingSetup) {
          this.routeLeaveMessage =
            'Are you sure you want to cancel the personio setup now?\n You have not yet saved the filters by clicking the continue button, which would prevent this data from being transferred!';
          this.showConfirmationModal(to.path, stepFilters);
        } else {
          next();
        }

        break;
      case 5:
        if (stepSchedule.isEditingSetup) {
          this.routeLeaveMessage =
            'Are you sure you want to cancel the personio setup now?\n You have not yet saved the automatic scheduler, which would prevent this data from being transferred!';
          this.showConfirmationModal(to.path, stepSchedule);
        } else {
          next();
        }

        break;
      default:
        next();
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const search = ref(null);
    const selectedCompany = ref(null);
    const availableCompanies = ref([]);
    const loadingCompanies = ref(false);
    const leaveConfirmationModal = ref(false);
    const catchedNavigationPath = ref(null);
    const leaveFromComponent = ref(null);
    const routeLeaveMessage = ref(null);
    const showConfirmationModal = (path, component) => {
      leaveConfirmationModal.value = true;
      leaveFromComponent.value = component;
      catchedNavigationPath.value = path;
    };

    const fetchCompany = (companyId) => {
      getCompanyById(companyId).then((res) => {
        selectedCompany.value = res.data;
        availableCompanies.value = [res.data];
      });
    };

    const confirmNavigationWithoutSaving = () => {
      leaveConfirmationModal.value = false;
      if (leaveFromComponent.value.reset) leaveFromComponent.value.reset();
      vm.$router.push(catchedNavigationPath.value);
    };

    watch(search, (val) => {
      if (!val) {
        vm.$router.replace({ query: null });
        return (availableCompanies.value = []);
      }
      loadingCompanies.value = true;

      getCompaniesList(0, 1000, val)
        .then((res) => {
          availableCompanies.value = res.data.page;
        })
        .finally(() => {
          loadingCompanies.value = false;
        });
    });

    if (vm.$route.query.company) {
      fetchCompany(vm.$route.query.company);
    }

    return {
      search,
      selectedCompany,
      loadingCompanies,
      availableCompanies,
      leaveConfirmationModal,
      showConfirmationModal,
      confirmNavigationWithoutSaving,
      routeLeaveMessage,
      catchedNavigationPath,

      icons: {
        mdiMagnify,
        mdiChevronDoubleLeft,
      },
    };
  },
};
</script>

<style lang="scss">
.confirm-modal {
  &__btns {
    padding-bottom: 36px;
  }
  &__btn {
    margin: 0 8px;
    min-width: 165px !important;
    border-radius: 3px !important;
  }
  &__text {
    max-width: 550px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #505a68;
  }
}
.notranslate {
  transform: none !important;
}
.personio-connect {
  .v-icon {
    transform: none !important;
  }
  &__back {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #2830ce;
  }
  &__overlay {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &__search {
    max-width: 500px;
  }
  &__input {
    background-color: white !important;
  }
  &__title {
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 23px;
  }
}
</style>
