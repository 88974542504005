<template>
  <v-stepper
    :key="rerenderKey"
    v-model="activeStep"
    flat
    class="transparent custom-header px-1 py-2"
    alt-labels
    height="auto"
  >
    <!-- Header -->
    <v-stepper-header :disabled="!companySelected">
      <!-- Header: Step 1 -->
      <v-stepper-step step="1" :complete="activeStep > 1" @click="activeStep = 1">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-sm font-weight-semibold">{{
            $t('Credentials')
          }}</span>
          <span v-else>{{ $t('Credentials') }}</span>
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-xs text--secondary">{{
            $t('Establish connection')
          }}</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 2 -->
      <v-stepper-step step="2" disabled :complete="activeStep > 2" @click="activeStep = 2">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-sm font-weight-semibold">{{
            $t('Field Mapping')
          }}</span>
          <span v-else>{{ $t('Field Mapping') }}</span>
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-xs text--secondary">{{
            $t('Bring table fields in line')
          }}</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 3 -->
      <v-stepper-step step="3" disabled :complete="activeStep > 3" @click="activeStep = 3">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-sm font-weight-semibold">{{
            $t('Value Mapping')
          }}</span>
          <span v-else>{{ $t('Value Mapping') }}</span>
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-xs text--secondary">{{
            $t('Link values of fields')
          }}</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 4 -->
      <v-stepper-step step="4" disabled :complete="activeStep > 4" @click="activeStep = 4">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-sm font-weight-semibold">{{
            $t('Filter Selection')
          }}</span>
          <span v-else>{{ $t('Filter Selection') }}</span>
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-xs text--secondary">{{
            $t('Sorting out data sets')
          }}</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 5 -->
      <v-stepper-step step="5" disabled @click="activeStep = 5">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-sm font-weight-semibold">{{
            $t('Synchronization')
          }}</span>
          <span v-else>{{ $t('Synchronization') }}</span>
          <span v-if="!$vuetify.breakpoint.smAndDown" class="text-xs text--secondary">{{
            $t('Schedule synchronization')
          }}</span>
        </div>
      </v-stepper-step>
    </v-stepper-header>

    <!-- Stepper Items -->
    <div class="card-shadow">
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <step-auth ref="stepAuth" :companySelected="companySelected" @stepForward="activeStep = 2" />
        </v-stepper-content>
        <!-- Stepper Content: Step 2 -->
        <v-stepper-content step="2" class="px-2 pt-0">
          <v-col cols="12" md="12" sm="12" :class="{ 'px-0': !$vuetify.breakpoint.smAndDown }" class="mx-auto">
            <p class="text-body-2">
              Here you will find the essential Degura fields that need to be connected in order to establish a correct
              link with the company data and Personio. For each required Degura field, please select the company
              equivalent. Please note that it may be necessary to consult with the company to select or populate the
              correct field names.
            </p>
            <step-field
              v-if="activeStep > 1"
              ref="stepFields"
              :companySelected="companySelected"
              @stepBack="activeStep = 1"
              @next="activeStep = 3"
            ></step-field>
          </v-col>
        </v-stepper-content>
        <!-- Stepper Content: Step 3 -->
        <v-stepper-content step="3" class="px-2 pt-0">
          <v-col cols="12" md="12" sm="12" :class="{ 'px-0': !$vuetify.breakpoint.smAndDown }" class="mx-auto">
            <p class="text-body-2 px-4">
              Here you can see the degura values used for each field. You now need to map each value of the Degura to
              the correct value used by the client company. Please contact the company to request the correct values if
              the dropdown menu does not indicate the ideal path, and then enter them manually.
            </p>
            <step-value
              v-if="activeStep > 2"
              ref="stepValues"
              :companySelected="companySelected"
              @stepBack="activeStep = 2"
              @next="activeStep = 4"
            ></step-value>
          </v-col>
        </v-stepper-content>
        <!-- Stepper Content: Step 4 -->
        <v-stepper-content step="4" class="px-2 pt-0">
          <v-col cols="12" md="12" sm="12" :class="{ 'px-0': !$vuetify.breakpoint.smAndDown }" class="mx-auto">
            <p class="text-body-2">
              Here you now have the option to filter the data by specific criteria, if necessary. To do this, please
              select the appropriate field and then determine how and by what should be filtered.
            </p>
            <step-filter
              v-if="activeStep >= 4"
              ref="stepFilters"
              :companySelected="companySelected"
              @stepBack="activeStep = 3"
              @next="activeStep = 5"
            ></step-filter>
          </v-col>
        </v-stepper-content>
        <!-- Stepper Content: Step 5 -->
        <v-stepper-content step="5" class="px-2 pt-0">
          <v-col cols="12" md="12" sm="12" :class="{ 'px-0': !$vuetify.breakpoint.smAndDown }" class="mx-auto">
            <p class="text-body-2 px-4">
              Now the synchronization of the data can be defined. There is a possibility to start a manual immediate
              synchronization or to set up a regular automatic synchronization.
            </p>
            <step-schedule
              ref="stepSchedule"
              :companySelected="companySelected"
              @stepBack="activeStep = 4"
            ></step-schedule>
          </v-col>
        </v-stepper-content>
      </v-stepper-items>
    </div>
  </v-stepper>
</template>

<script>
import { ref, watch, toRefs, getCurrentInstance } from '@vue/composition-api';
import { mdiCached, mdiCheckBold, mdiAlert } from '@mdi/js';
import StepAuth from './step-auth/PersonioConnectStepAuth';
import StepField from './step-field/PersonioConnectStepField';
import StepValue from './step-value/PersonioConnectStepValue';
import StepFilter from './step-filter/PersonioConnectStepFilter';
import StepSchedule from './step-scheduler/PersonioConnectStepSchedule';

export default {
  name: 'PersonioConnectStepper',
  components: { StepAuth, StepField, StepValue, StepFilter, StepSchedule },
  props: {
    companySelected: {
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const activeStep = ref(1);
    const rerenderKey = ref(0);
    const { companySelected } = toRefs(props);

    watch(companySelected, () => {
      if (!vm.$route.query.step) activeStep.value = 1;
      else activeStep.value = Number(vm.$route.query.step) + 1;
      rerenderKey.value += 1;
    });

    return {
      activeStep,
      rerenderKey,
      icons: { mdiCached, mdiCheckBold, mdiAlert },
    };
  },
};
</script>

<style lang="scss">
.v-stepper__label {
  color: #2830ce !important;
}
.v-stepper__header {
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.v-stepper__wrapper {
  padding: 10px;
}
.v-stepper__step {
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}

.cursor-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.v-stepper__header {
  .v-divider {
    border-color: rgba(40, 48, 206, 0.25) !important;
    padding: 1px;
    border-width: 2px !important;
  }
}
</style>
