var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"schedule-card"},[_c('div',{staticClass:"schedule-card__title"},[_vm._v("Set up automatic synchronization")]),_c('v-form',{ref:"personioScheduleForm",staticClass:"personioScheduleForm",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-select',{staticClass:"mb-5 mt-2",attrs:{"items":_vm.repeatOptions,"label":"Repetition","placeholder":"Repetition","outlined":"","hide-details":"","dense":""},on:{"change":function($event){(_vm.saveChangesAllowed = true), _vm.setRepeatOptions()}},model:{value:(_vm.repeat),callback:function ($$v) {_vm.repeat=$$v},expression:"repeat"}}),_c('v-menu',{ref:"menu",staticClass:"datepicker-month",attrs:{"close-on-content-click":false,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-5",attrs:{"outlined":"","dense":"","label":"Time of sync\n                ","readonly":"","hide-details":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{staticClass:"mb-0",attrs:{"format":"24hr","color":"primary","full-width":"","hide-details":"","min":_vm.minAllowedTime},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)},"change":function($event){_vm.saveChangesAllowed = true}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1),(_vm.repeat === null)?_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"N/A","readonly":"","outlined":"","dense":"","hide-details":"","disabled":"","value":"Please choose a repetition","filled":""}}):_vm._e(),(_vm.repeat === 'ONCE' || _vm.repeat === 'ONCE')?_c('v-menu',{ref:"menurefdate",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of sync\n                ","readonly":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,271718255),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"full-width":"","min":_vm.minAllowedDate,"max":_vm.maxAllowedDate,"color":"primary","picker-date":_vm.date},on:{"update:pickerDate":function($event){_vm.date=$event},"update:picker-date":function($event){_vm.date=$event},"click:date":function($event){return _vm.$refs.menurefdate.save(_vm.date)},"change":function($event){(_vm.saveChangesAllowed = true), _vm.setRepeatOptions()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),(_vm.repeat === 'MONTHLY')?_c('v-menu',{ref:"menurefdatemonth",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"\n                Date of sync\n                ","readonly":"","outlined":"","dense":"","hide-details":"","value":'on the ' + _vm.moment(_vm.dateMonth).format('Do')}},'v-text-field',attrs,false),on))]}}],null,false,1752636591),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{class:_vm.repeat === 'MONTHLY' ? 'datepicker-month' : '',attrs:{"full-width":"","min":_vm.minAllowedDate,"max":_vm.maxAllowedDate,"color":"primary","no-title":_vm.repeat === 'MONTHLY',"show-current":false},on:{"click:date":function($event){return _vm.$refs.menurefdatemonth.save(_vm.date)},"change":function($event){(_vm.saveChangesAllowed = true), _vm.setRepeatOptions()}},model:{value:(_vm.dateMonth),callback:function ($$v) {_vm.dateMonth=$$v},expression:"dateMonth"}})],1):_vm._e(),(_vm.repeat === 'TWICEMONTHLY')?_c('v-menu',{ref:"menurefdatemonthbi",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"\n                  'Dates of sync'\n                ","readonly":"","outlined":"","dense":"","hide-details":"","value":'on the ' + _vm.moment(_vm.dateTwiceMonth[0]).format('Do') + '/' + _vm.moment(_vm.dateTwiceMonth[1]).format('Do')}},'v-text-field',attrs,false),on))]}}],null,false,1366259456),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{class:_vm.repeat === 'TWICEMONTHLY' ? 'datepicker-month datepicker-range' : '',attrs:{"full-width":"","min":_vm.minAllowedDate,"max":_vm.maxAllowedDate,"color":"primary","no-title":_vm.repeat === 'TWICEMONTHLY',"show-current":false,"range":""},on:{"click:date":function($event){_vm.dateTwiceMonth.length === 2 ? _vm.$refs.menurefdatemonthbi.save(_vm.date) : ''},"change":function($event){(_vm.saveChangesAllowed = true), _vm.setRepeatOptions()}},model:{value:(_vm.dateTwiceMonth),callback:function ($$v) {_vm.dateTwiceMonth=$$v},expression:"dateTwiceMonth"}})],1):_vm._e(),(_vm.repeat === 'DAILY')?_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"N/A","readonly":"","outlined":"","dense":"","hide-details":"","disabled":_vm.repeat === 'DAILY',"value":"Day or date does not apply","filled":""}}):_vm._e(),(_vm.repeat === 'WEEKLY')?_c('v-select',{staticClass:"mb-5 mt-2",attrs:{"items":_vm.dayOptions,"label":"Day of sync","placeholder":"Please choose a day","outlined":"","hide-details":"","dense":""},on:{"change":function($event){(_vm.saveChangesAllowed = true), _vm.setRepeatOptions()}},model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}}):_vm._e(),_c('v-btn',{staticClass:"schedule-card__btn",class:{
              'schedule-card__btn--disabled': !_vm.time || !_vm.date || !_vm.repeat,
            },attrs:{"color":"primary","disabled":!_vm.time || !_vm.date || !_vm.repeat || _vm.isSyncRunning || !_vm.saveChangesAllowed,"type":"submit"},on:{"click":_vm.saveSchedule}},[_vm._v(" Save changes ")])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"schedule-card d-flex flex-column justify-space-between"},[_c('div',{staticClass:"schedule-card__title mb-0"},[_vm._v("Start manual instant synchronization")]),_c('div',{staticClass:"schedule-card__icons justify-center"},[_c('v-icon',{staticClass:"schedule-card__db",attrs:{"size":"120","color":"#898989"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDatabase)+" ")]),_c('v-icon',{staticClass:"schedule-card__sync animation-spin-back",class:{ 'animation-spin-back': _vm.isSyncRunning },attrs:{"size":"49","color":"#898989"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSync)+" ")]),_c('v-icon',{staticClass:"schedule-card__db",attrs:{"size":"120","color":"#898989"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDatabase)+" ")])],1),_c('v-btn',{staticClass:"schedule-card__btn mt-0",attrs:{"color":"primary","disabled":_vm.isSyncRunning,"type":"submit"},on:{"click":_vm.syncNow}},[_vm._v(" Sync now ")])],1)])],1),_c('div',{staticClass:"d-flex mt-10"},[_c('v-btn',{staticClass:"flex-button personio-step__auth-btn",attrs:{"outlined":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('stepBack')}}},[_vm._v(" Back to filter selection ")]),_c('v-spacer'),_c('v-btn',{staticClass:"flex-button personio-step__auth-btn accent--text",attrs:{"color":"primary"},on:{"click":_vm.finishSetup}},[_vm._v(" Finish personio connector ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }