<template>
  <div class="personio-step__auth d-flex">
    <div class="personio-step__auth-logo">
      <img :src="require('@/assets/static/images/PersonioLogoLarge.svg')" alt="" />
    </div>
    <v-form ref="personioCredsForm" v-model="isFormValid" class="personio-step__auth-form" @submit.prevent="onSubmit">
      <p class="personio-step__auth-description">
        These credentials will be provided by the company to link their personio data. Please keep it confidential! As
        we are not saving these data, you have to reenter it, once you go back to this credentials step.
      </p>
      <v-text-field
        v-model="personioId"
        :disabled="!companySelected"
        :type="isPersonioIdVisible ? 'text' : 'password'"
        :append-icon="isPersonioIdVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
        outlined
        dense
        hide-details
        full-width
        placeholder="Personio ID"
        class="mb-4"
        @click:append="isPersonioIdVisible = !isPersonioIdVisible"
      ></v-text-field>
      <v-text-field
        v-model="personioSecret"
        :disabled="!companySelected"
        :type="isPersonioSecretVisible ? 'text' : 'password'"
        :append-icon="isPersonioSecretVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
        outlined
        dense
        hide-details
        full-width
        placeholder="Personio Secret"
        class="mb-4"
        @click:append="isPersonioSecretVisible = !isPersonioSecretVisible"
      ></v-text-field>
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          :disabled="!personioSecret || !personioId"
          class="flex-button personio-step__auth-btn"
          type="submit"
        >
          Continue to field mapping
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref, toRefs, getCurrentInstance } from '@vue/composition-api';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import {
  postValidateCredentials,
  postConnectPersonio,
  signPersonioActivity,
  getPersonioCompanyById,
  putUpdatePersonio,
} from '../../../../api/personio-connector';
import { ADMIN_ACTIVITY_TYPE } from '@/constants';

export default {
  name: 'PersonioConnectStepAuth',
  props: {
    companySelected: {
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const isConnected = ref(false);
    const isFormValid = ref(false);
    const isPersonioIdVisible = ref(false);
    const isPersonioSecretVisible = ref(false);
    const personioCredsForm = ref(null);
    const personioId = ref('');
    const personioSecret = ref('');

    const { companySelected } = toRefs(props);

    const signActivity = (isEdit = false) => {
      const userName =
        userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;
      const msg = isEdit ? `${userName} changed credentials` : `${userName} saved credentials successful`;
      signPersonioActivity(companySelected.value.id, userData.id, { msg }, ADMIN_ACTIVITY_TYPE.INFO);
    };

    const onSubmit = () => {
      const payload = {
        portalCompanyId: companySelected.value.id,
        name: companySelected.value.companyName,
        filters: [],
        targetToSourceFieldMapping: {},
        fieldEnumMapping: {},
        clientId: personioId.value,
        clientSecret: personioSecret.value,
        schedule: '',
        step: 1,
        draft: true,
      };

      postValidateCredentials({
        clientId: personioId.value,
        clientSecret: personioSecret.value,
      })
        .then(() => {
          if (isConnected.value || !!vm.$route.query.step) editCreds();
          else {
            postConnectPersonio(payload).then(() => {
              isConnected.value = true;
              signActivity();
              vm.$store.commit('showMessage', {
                text: `The connection to Personio from ${companySelected.value.companyName} was successful!`,
                color: 'teal',
                timeout: '5000',
              });

              reset();
              vm.$emit('stepForward');
            });
          }
        })
        .catch(() => {
          reset();
          vm.$store.commit('showMessage', {
            text: `The connection to Personio from ${companySelected.value.companyName} was not successful! Please check the ID and secret again.`,
            color: 'error',
            timeout: '-1',
          });
        });
    };

    const editCreds = () => {
      const payload = {
        portalCompanyId: companySelected.value.id,
        clientId: personioId.value,
        clientSecret: personioSecret.value,
      };

      putUpdatePersonio(companySelected.value.id, payload).then(() => {
        signActivity(true);
        vm.$store.commit('showMessage', {
          text: `The connection to Personio from ${companySelected.value.companyName} was successful!`,
          color: 'teal',
          timeout: '5000',
        });

        reset();
        vm.$emit('stepForward');
      });
    };

    const reset = () => {
      personioSecret.value = '';
      personioId.value = '';
    };

    return {
      personioCredsForm,
      isFormValid,
      personioId,
      personioSecret,
      isPersonioSecretVisible,
      isPersonioIdVisible,
      onSubmit,
      reset,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    };
  },
};
</script>

<style scoped lang="scss">
.personio-step {
  &__auth {
    &-logo {
      margin-right: 100px;
      width: 374px;
      border-right: 1px solid #f3f3f3;
    }
    &-description {
      max-width: 501px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 24px;
    }
    &-btn {
      border-radius: 3px;
      min-width: 230px !important;
    }
  }
}
</style>
